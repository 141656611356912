import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right2.icon.svg'
import { Request } from './Request'
import axios from 'axios'
import { Loader } from '../../../Components/shared/Loader'
import { EmptyState } from '../../../Components/EmptyState'
import callTone from "../../../audio/pickup.mp3"


const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export const RequestLists = () => {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [ring,setRing] =  useState(new Audio(callTone));
  const [stopRinging, setStopRinging] = useState(false);
  const dummyRef = useRef(null);
  const accessToken = localStorage.getItem('authAccessToken');

  const fetchUserRequests = async () => {
    try {
      const response = await axios.get(`${baseUrl}/client/mechanic-requests/`, {headers: {'Authorization': `Bearer ${accessToken}`,}});
      console.log(response);
      setIsLoading(false);
      setRequests(response.data.data.mechanic_requests);
    } catch (error) {
      console.log(error);
    }
  }

  const handleRingingStop = ()=>{
    setStopRinging(init=> true)
  }

  useEffect(() => {
    fetchUserRequests();
  }, [])

  useEffect(function(){
    setStopRinging(init=>false);
    if(dummyRef.current != null){
        dummyRef.current.click();
    }
    if ( ( requests.length > 0 ) && !stopRinging ) {      
      console.log("there should be ringing any moment from now  since request length is equal to ",requests);
        ring.play()
        .catch(function(error){console.log(error);})
        ring.loop = true;
    }else{    
      ring.pause();
    }
  },[requests]);


  return (
    <RequestsView>
      <SearchView>
        <input type="search" className='search-input' placeholder="Search" />
      </SearchView>

      <div className="cta">
        <p className='message'>You have {requests.length} incoming {requests.length === 0 || 1 ? 'request' : 'requests'}</p>
        <button className='hidden' ref={dummyRef} onClick={()=>{console.log("dummy was clicked");}}></button>
        <button onClick={() => navigate('/mechanic/scheduled-calls')}>
          <ArrowRight />
        </button>
      </div>

      {requests.length === 0 ? (
        <EmptyState />
      ) : (
        <Loader isLoading={isLoading}>
          <Request 
            requests={requests} 
            fetchUserRequests={fetchUserRequests}
            stopRinging={handleRingingStop}
          />
        </Loader>
      )}


    </RequestsView>
  )
}

const RequestsView = styled.div`
  margin-bottom: 5rem;

  .cta {
    display: flex;
    margin: 1rem 0;
    border-radius: 5px;
    align-items: center;
    padding: 0.8rem 1rem;
    background-color: #EAFFF8;
    justify-content: space-between;

    .message {
      color: #245444;
    }
  }
`;

const SearchView = styled.div`
  .search-input {
    width: 100%;
    padding: 0.6rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-indent: 0.7rem;
    color: #cccccc;

    &::placeholder {
      font-size: 0.8rem;
    }

    &:focus {
      outline: none;
    }
  }
`;